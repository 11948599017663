import request from '@/utils/request'
const baseApi = '/admin/aiWorkshop'
// 根据抖音地址获取视频信息
export function analysisUrl(query) {
  // 发送POST请求，请求地址为baseApi + '/analysisUrl'，参数为query
  return request({
    url: baseApi + '/analysisUrl',
    method: 'POST',
    params: query
  })
}
// 视频采集——单功能
export function videoGather(data) {
  return request({
    url: baseApi + '/videoGather',
    method: 'POST',
    data
  })
}
// 视频搬运——整合功能
export function tkTransport(data) {
  return request({
    url: baseApi + '/tkTransport',
    method: 'POST',
    data
  })
}
