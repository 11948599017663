import request from '@/utils/request'
// 爆款拆解
const baseApi = '/admin/popular'

// 列表
export function listPopular(query) {
  return request({
    url: baseApi + '/list',
    method: 'get',
    params: query
  })
}
// 详情
export function getPopularDetail(id) {
  return request({
    url: baseApi + '/' + id,
    method: 'get'
  })
}
