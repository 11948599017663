import request from '@/utils/request'
const baseApi = '/admin/aiWorkshop'

export function setMergeFace(data) {
  return request({
    url: baseApi+'/mergeFace',
    method: 'POST',
    data
  })
}

export function getTaskList(params) {
  return request({
    url: baseApi+'/list',
    method: 'GET',
    params
  })
}

export function eraseSubtitle(data) {
  return request({
    url: baseApi+'/eraseSubtitle',
    method: 'POST',
    data
  })
}
