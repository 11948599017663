import request from '@/utils/request'
// 套餐
const baseApi = '/mall/package'
// 列表
export function listPackage(query) {
  return request({
    url: baseApi + '/list',
    method: 'get',
    params: query
  })
}

