import request from '@/utils/request'
const baseApi = '/admin/aiWorkshop'

export function addSplitVideo(data) {
  return request({
    url: baseApi+'/split',
    method: 'POST',
    data
  })
}
