import request from '@/utils/request'
// 视频翻译
export function videoTranslate(data) {
  return request({
    url: '/admin/aiWorkshop/videoTranslation',
    method: 'post',
    data: data
  })
}

export function videoTranslation(data) {
  return request({
    url: '/admin/aiWorkshop/videoTranslationApp',
    method: 'POST',
    data
  })
}
