import request from '@/utils/request'
// 账单
const baseApi = '/mall/bill'
// 列表
export function listMallBill(query) {
  return request({
    url: baseApi + '/buyList',
    method: 'get',
    params: query
  })
}
// 财务总览（商家）
export function listMallSell(query) {
  return request({
    url: baseApi + '/sellList',
    method: 'get',
    params: query
  })
}
