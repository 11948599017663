import request from '@/utils/request'
// 钱包
const baseApi = '/mall/wallet'
// 列表
export function myWallet(query) {
  return request({
    url: baseApi + '/myWallet',
    method: 'get',
    params: query
  })
}
