import request from '@/utils/request'
//通知消息
const baseApi = '/resource/noticeMessage'

// 列表
export function listNoticeMessage(query) {
  return request({
    url: baseApi + '/list',
    method: 'get',
    params: query
  })
}
// 详情
export function readNoticeMessage(query) {
  return request({
    url: baseApi + '/read',
    method: 'post',
    params: query
  })
}
