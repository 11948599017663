import request from '@/utils/request'
import { parseStrEmpty } from "@/utils/ruoyi";

// 查询用户列表
export function listUser(query) {
  return request({
    url: '/system/user/list',
    method: 'get',
    params: query
  })
}

// 查询用户详细
export function getUser(userId) {
  return request({
    url: '/system/user/' + parseStrEmpty(userId),
    method: 'get'
  })
}

// 新增用户
export function addUser(data) {
  return request({
    url: '/system/user',
    method: 'post',
    data: data
  })
}

// 修改用户
export function updateUser(data) {
  return request({
    url: '/system/user',
    method: 'put',
    data: data
  })
}

// 删除用户
export function delUser(userId) {
  return request({
    url: '/system/user/' + userId,
    method: 'delete'
  })
}

// 用户密码重置
export function resetUserPwd(userId, password) {
  const data = {
    userId,
    password
  }
  return request({
    url: '/system/user/resetPwd',
    method: 'put',
    data: data
  })
}

// 用户状态修改
export function changeUserStatus(userId, status) {
  const data = {
    userId,
    status
  }
  return request({
    url: '/system/user/changeStatus',
    method: 'put',
    data: data
  })
}

// 查询用户个人信息
export function getUserProfile() {
  return request({
    url: '/system/user/profile',
    method: 'get'
  })
}

// 修改用户个人信息
export function updateUserProfile(data) {
  return request({
    url: '/system/user/profile',
    method: 'put',
    data: data
  })
}
// 完善个人信息——暂时没用到，又改回了上面的接口
export function updateCompany(data) {
  return request({
    url: '/system/user/profile/updateCompany',
    method: 'put',
    data: data
  })
}

// 用户密码重置
export function updateUserPwd(oldPassword, newPassword) {
  const data = {
    oldPassword,
    newPassword
  }
  return request({
    url: '/system/user/profile/updatePwd',
    method: 'put',
    params: data
  })
}

// 用户头像上传
export function uploadAvatar(data) {
  return request({
    url: '/system/user/profile/avatar',
    method: 'post',
    data: data
  })
}

// 查询授权角色
export function getAuthRole(userId) {
  return request({
    url: '/system/user/authRole/' + userId,
    method: 'get'
  })
}

// 保存授权角色
export function updateAuthRole(data) {
  return request({
    url: '/system/user/authRole',
    method: 'put',
    params: data
  })
}

// 查询部门下拉树结构
export function deptTreeSelect() {
  return request({
    url: '/system/user/deptTree',
    method: 'get'
  })
}

//快速注册
export function createFreeAccount() {
  return request({
    url: '/system/user/createFreeAccount',
    method: 'get'
  })
}
// 查询登录用uuid
export function getLoginUUid() {
  return request({
    url: '/auth/wx/uuid/get',
    method: 'get'
  })
}

// uuid登录-获取登录状态
export function uuidLogin(data) {
  return request({
    url: '/auth/wx/uuid/login?uuid=' + data.uuid,
    method: 'get'
  })
}
// 设置手机号
export function setPhone(data) {
  return request({
    url: '/system/user/profile/setPhone',
    method: 'post',
    data
  })
}
// 设置邮箱
export function setEmail(data) {
  return request({
    url: '/system/user/profile/setEmail',
    method: 'post',
    data
  })
}


// 微信公众号关注登录相关
export function QRcode() {
  return request({
    url: '/auth/wx/qr/login/param',
    method: 'get'
  })
}

export function loginwx(wxuuid) {
  return request({
    url: '/auth/wxLogin/' + wxuuid,
    headers: {
      isToken: false
    },
    method: 'get',
  })
}
