import request from '@/utils/request'
//工作台
const baseApi = 'admin/dynamics'

// 社区动态
export function getCommunity(params) {
  return request({
    url: baseApi+'/list',
    method: 'get',
    params
  })
}
